import React from 'react';
import { Button } from '@mui/material';
import { Helmet } from 'react-helmet';

function Pricing() {
  return (
    <div className="TaylrHomePage">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Pricing - Taylr.AI</title>
            <link rel="canonical" href={"https://www.taylr.ai"+window.location.pathname} />
            <meta name="description" content={`Find out how much a subscription with Taylr costs` }/>
            <meta name="og:title" content={`Pricing - Taylr.AI`}/>
            <meta name="og:type" content={"website"}/>
            <meta name="og:url"  href={"https://www.taylr.ai"+window.location.pathname} />
            <meta name="og:image"  content={"https://www.taylr.ai/Taylr_logo_blue_and_yellow_and_blue.png"} />
        </Helmet>
        <div className="TaylrHomePageTop">
            <div className="TaylrPricingTitle">Choose the right plan for you</div>
            <div className="TaylrPricingSubtitle">Don't let writing hold you back any longer</div>
            <div className="PlansContainer">

                <div className="Plan">
                    <div className="PlanTitle">
                        Single User
                    </div>
                    <div className="PlanDescription">
                        Ideal for an individual recruiter looking to save time 
                    </div>
                    <div className="PlanPrice">
                        <div className="PoundIcon">£</div>
                        <div className="PlanPriceNumber">29</div>
                        <div className="PlanPriceInterval">/mo</div>
                    </div>
                    <div className="PlanCredits">35,000 credits per month</div>
                    <div className="PlanNumbeUsers">Single User</div>
                    <div className="PlanButtons">
                        {/* <Button onClick={planClick} className="LoginButton" variant="contained">Check out</Button> */}
                        <a href="https://app.taylr.ai/register" style={{textDecoration: "none"}}>
                            <Button id="checkout-and-portal-button"  className="PlanButton LoginButton"  type="submit">
                                Try for Free
                            </Button>
                          </a>
                    </div>
                    <div className="PlanBilling">
                        Billed Monthly
                    </div>
                    <div className="PlanBullets">
                      {/* <div className="PlanTimeSaving">Save 15 hours per month</div> */}
                      {/* <ul>
                        <li>Remove writers block</li>
                        <li>Write quality content</li>
                        <li>Generate Job Adverts</li>
                      </ul> */}
                      {/* <ul>
                        <li>~140 Job Adverts</li>
                        <li>or 300 LinkedIn Posts</li>
                        <li>or 400 Personalised emails</li>
                      </ul> */}
                      <div className="PlanTimeSaving">Save 20 hours per month
                      <div className="PlanTimeSavingText">Estimated</div></div>
                      
                    </div>
                </div>
                <div className="Plan">
                    <div className="PlanTitle">
                        Business
                    </div>
                    <div className="PlanDescription">
                        Help to make your team more efficient
                    </div>
                    <div className="PlanPrice">
                        <div className="PoundIcon">£</div>
                        <div className="PlanPriceNumber">49</div>
                        <div className="PlanPriceInterval">/mo</div>
                    </div>
                    <div className="PlanCredits">80,000 credits per month</div>
                    <div className="PlanNumbeUsers">Up to 10 Users</div>
                    <div className="PlanButtons">
                      <a href="https://app.taylr.ai/register?redirect=plans" style={{textDecoration: "none"}}>
                            <Button id="checkout-and-portal-button"  className="PlanButton CheckoutButton"  type="submit">
                                Start Now
                            </Button>
                          </a>
                    </div>
                    <div className="PlanBilling">
                        Billed Monthly
                    </div>
                    <div className="PlanBullets">
                      {/* 80,000 credits is equal to
                      <ul>
                        <li>~320 Job Adverts</li>
                        <li>or 700 LinkedIn Posts</li>
                        <li>or 900 Personalised emails</li>
                      </ul> */}
                      <div className="PlanTimeSaving">Save 45 hours per month
                      <div className="PlanTimeSavingText">Estimated across your team</div>
                      </div>
                      
                    </div>
                </div>
            </div>
            <div className="PricingContactUs">
                <div className="PricingContactUsTitle">Need more than 10 users?</div>

                  <a href="mailto:aylin@taylr.ai" style={{textDecoration: "none"}}>
                    <Button id="checkout-and-portal-button"  className="PlanButton LoginButton"  type="submit">
                        Contact Us
                    </Button>
                  </a>
            </div>
        </div>
        <div className="TaylrHomePageSecond">
            <div className="TaylrHomePageSection">

                <div className="TaylrHomePageSecondaryTitle">FAQ</div>
                <div className="TaylrHomePageSecondaryText">
                  <p>
                    <div className="TaylrBoldHeader">
                      What are credits?
                    </div>
                    Credits are how we count words generated. 10,000 credits is 10,000 words, or roughly equal to 30 job descriptions.
                  </p>
                  <p>
                    <div className="TaylrBoldHeader">
                      How much time does Taylr Save?
                    </div>
                    Taylr can help you write up to 10x faster. If a job advert takes you 30 minutes to write, with Taylr you can do it in 3! 
                  </p>
                  <p>
                    <div className="TaylrBoldHeader">
                      What happens if I run out of credits?
                    </div>
                    You can either wait until your subscription renews, or upgrade to the next tier of plan.
                  </p>
                  <p>
                    <div className="TaylrBoldHeader">
                      Do I keep any unused credits?
                    </div>
                    Unused credits do not roll over into the next month, so make sure you make the most of them!
                  </p>
                    {/* <a href="https://app.taylr.ai/register" className="TaylrHomeButtonContainer">
                        <Button variant="contained" className="TaylrButton TryNowButton">Get Started</Button>
                    </a> */}
                </div>
            </div>
        </div>
    </div>
  );
}

export default Pricing;