import React, { useState, useEffect } from 'react';
import TextTransition, { presets } from "react-text-transition";
import { Button } from '@mui/material';
import { FaQuoteLeft } from 'react-icons/fa';
import { BsLinkedin } from 'react-icons/bs';
import { Helmet } from 'react-helmet';

function Home() {

    const items = ["Job Adverts", "Boolean Strings", "LinkedIn Posts", "Client Emails", "Outreach Messages", "LinkedIn Polls", "Post Ideas"];
    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
      const intervalId = setInterval(() =>
        setIndex(index => (index + 1)%items.length),
        2000 // every 3 seconds
      );
      return () => clearTimeout(intervalId);
    }, []);


  return (
    <div className="TaylrHomePage">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Taylr.AI</title>
            <link rel="canonical" href={"https://www.taylr.ai"+window.location.pathname} />
            <meta name="description" content={`The AI Writing Assistant for Recruiters` }/>
            <meta name="og:title" content={`Taylr.AI`}/>
            <meta name="og:type" content={"website"}/>
            <meta name="og:url"  href={"https://www.taylr.ai"+window.location.pathname} />
            <meta name="og:image"  content={"https://www.taylr.ai/Taylr_logo_blue_and_yellow_and_blue.png"} />
        </Helmet>
        <div className="TaylrHomePageTop">
            <div className="TaylrHomePageTopSection">
                <div className="TaylrHomePageTopLeft">
                    <h1 className="TaylrHeader">
                        <div>The AI Writer for Recruiters</div> 
                    </h1>
                    <h2 className="TaylrHeaderSecondary">
                        <div className="TaylrHeaderSecondaryText">Taylr crafts engaging {" "}
                        <TextTransition springConfig={presets.gentle} className="HighlightedText">
                        {items[index]}
                    </TextTransition>
                    </div><div>in seconds</div></h2>
                    <a href="https://app.taylr.ai/register" className="TaylrHomeButtonContainer">
                        <Button variant="contained" className="TaylrButton TryNowButton">Try for Free</Button>
                    </a>
                </div>
                <div className="TaylrHomeImgContainer">
                    <img src="/TaylrHome.PNG" className="TaylrHomePageImg" alt="Taylr Product Preview"/>
                </div>
            </div>
        </div>
        <div className="TaylrHomePageSecond">
            <div className="TaylrHomePageSection">
            <div className="TaylrHomePageSecondaryTitle">Is writer’s block hindering your recruitment efforts?</div>
                <div className="TaylrHomePageSecondaryText">
                    {/* <p/> */}
                        <ul>
                            <li>Tired of spending hours writing new job adverts?</li>
                            <li>Do you find yourself copy-pasting old ads, due to lack of time? </li>
                            <li>Are your job ads just a bit bland and don’t attract the right candidates?</li>
                            <li>Do you overthink writing your sales emails and wasting precious time?</li>
                            <li>Are you running out off time to build and engage with your LinkedIn network?</li>
                            <li>Are you lacking ideas on what to post on LinkedIn?</li>
                        </ul>
                    <p>If you're not using ChatGPT to help write your adverts and emails, you’re letting your competitors get a head start.<p/>It doesn’t have to be this way.</p>

                {/* <div className="TaylrHomePageSecondaryTitle">Save Time and Boost your Productivity with Taylr</div>
                <div className="TaylrHomePageSecondaryText">
                    <p>Tired of spending hours crafting job ads or copy-pasting old descriptions? 😩 <p/> Struggling to keep up with your LinkedIn presence due to a lack of inspiration and time? 😰 <p/> Don't let writing slow you down - introducing Taylr, your AI writing assistant that tackles the time-consuming tasks for you.</p>
                    <p><div className="TaylrBoldHeader">With Taylr, you can:</div>
                        <ul>
                            <li>Create text 10x faster</li>
                            <li>Avoid writer's block</li>
                            <li>Tailor your job adverts for maximum impact</li>
                            <li>Craft compelling sales emails for clients and candidates</li>
                            <li>Write engaging LinkedIn posts</li>
                            <li>Increase your LinkedIn network and presence</li>
                            <li>Improve your recruitment process</li>
                        </ul>
                    </p>
                    <p>Taylr's AI writing tool for recruiters can help you save time, boost productivity, and focus on what matters most - finding and attracting the right candidates. 🙂<p/> Discover the benefits of using Taylr for free today.</p> */}
                    <a href="https://app.taylr.ai/register" className="TaylrHomeButtonContainer">
                        <Button variant="contained" className="TaylrButton TryNowButton">Get Started</Button>
                    </a>
                </div>
            </div>
        </div>
        <div className="TaylrHomePageBlue">
            <div className="TaylrHomePageBenefitsSection">
                <div className="TaylrHomePageYellowTitle">Discover the recruitment benefits of Taylr</div>
                <div className="TaylrHomePageSectionSubtitle">
                    The only AI writing assistant designed specifically for recruitment professionals.
                </div>
                <div className="TaylrHomePageBenefits">
                    <div className="TaylrHomePageBenefit">
                        <div className="TaylrHomePageBenefitTitle">Create Text 10x Faster</div>
                        <div className="TaylrHomePageBenefitText">Write job ads, outreach emails, and LinkedIn content with ease using Taylr's AI writing assistant.</div>
                    </div>
                    <div className="TaylrHomePageBenefit">
                        <div className="TaylrHomePageBenefitTitle">Maximise Your Productivity</div>
                        <div className="TaylrHomePageBenefitText">Taylr handles the time consuming tasks and frees you up to find and attract more candidates.</div>
                    </div>
                    <div className="TaylrHomePageBenefit">
                        <div className="TaylrHomePageBenefitTitle">Avoid Writer's Block</div>
                        <div className="TaylrHomePageBenefitText">Never stare at a blank page again - let Taylr help get you started and get more done.</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="TaylrHomePageSecond">
            <div className="TaylrHomePageSecondaryTitle">Here's what our Users have to say</div>
            <div className="TaylrHomePageTestimonials">
                <div className="TaylrHomePageTestimonial">
                    <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                    <div className="TaylrHomePageTestimonialText">
                        <p>Taylr has been a game-changer to support me in my day to day. </p>

                        <p>It helps me to write job adverts in minutes, freeing up time for other important tasks. </p>

                        <p>The anonymisation function is also a standout feature that saves me a lot of hassle - I can take a job ad from a client and rewrite it for my job board in seconds. </p>

                        <p>I highly recommend Taylr to any recruiter looking to streamline their writing process. </p>
                    </div>
                    <div className="TaylrTestimonialProfile">
                        <img src="/StephenDrew.jfif" alt="Stephen Drew's Photo" className="TestimonialImage"></img>
                        <div className="TaylrTestimonialProfileText">
                            <div className="TestimonialName">Stephen Drew FRSA</div>
                            <div className="TestimonialTitle">Founder</div>
                            <a className="TestimonialCompany" href="https://architecturesocial.com?utm_source=taylr&utm_medium=testimonial&utm_campaign=taylr" target="_blank"> Architecture Social</a>
                        </div>
                    </div>
                </div>
                <div className="TaylrHomePageTestimonial">
                    <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                    <div className="TaylrHomePageTestimonialText">
                        <p>I really enjoy using Taylr.  </p>

                        <p>The Job Advert feature and Anonymiser are fantastic and have saved me a lot of late nights writing up jobs. </p>

                        <p>I used to use ChatGPT to help write my adverts and it can feel like a fight to get the right output, but with Taylr it gets it right almost every time. </p>
                    </div>
                    <div className="TaylrTestimonialProfile">
                        <img className="TestimonialImage" src="/BrianFraser.jfif" alt="Brian Fraser's Photo"></img>
                        <div className="TaylrTestimonialProfileText">
                            <div className="TestimonialName">Brian Fraser</div>
                            <div className="TestimonialTitle">Head of Marketing & Digital Recruitment</div>
                            <a className="TestimonialCompany"href="https://www.weareninetwenty.com/?utm_source=taylr&utm_medium=testimonial&utm_campaign=taylr" target="_blank">Nine Twenty Recruitment</a>
                        </div>
                    </div>
                </div>
                <div className="TaylrHomePageTestimonial">
                    <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                    <div className="TaylrHomePageTestimonialText">
                        <p>Taylr is an excellent tool for recruiters or anyone who doesn't enjoy typing. </p>

                        <p>Whether it's a LinkedIn post or a job advert, Taylr has saved me time and sanity. </p>

                        <p>I would highly recommend it, without any hesitation. 100% would recommend!</p>
                    </div>
                    <div className="TaylrTestimonialProfile">
                        <img src="/TamaraManukyan.png" alt="Tamara Manukyan's Photo" className="TestimonialImage"></img>
                        <div className="TaylrTestimonialProfileText">
                            <div className="TestimonialName">Tamara Manukyan</div>
                            <div className="TestimonialTitle">Recruitment Consultant</div>
                            <a className="TestimonialCompany" href="https://www.markssattin.co.uk/?utm_source=taylr&utm_medium=testimonial&utm_campaign=taylr" target="_blank"> Marks Sattin</a>
                        </div>
                    </div>
                </div>
                <div className="TaylrHomePageTestimonial">
                    <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                    <div className="TaylrHomePageTestimonialText">
                        <p>Since using Taylr.AI, I have got some all-important time back to focus on recruitment without stressing over writing up my job briefs, LinkedIn posts and more.  </p>

                        <p>It has been an incredibly useful tool for day-to-day use and those times when my mind goes blank and can’t think of anything creative to write. </p>

                        <p>Taylr.AI has come on leaps and bounds since I first started using it and now it one of my must haves.</p>

                        <p>With features being constantly added, I look forward to seeing what it can help with next! </p>
                    </div>
                    <div className="TaylrTestimonialProfile">
                        <img src="/crawfordauld.png" alt="Crawford Auld's Photo" className="TestimonialImage"></img>
                        <div className="TaylrTestimonialProfileText">
                            <div className="TestimonialName">Crawford Auld</div>
                            <div className="TestimonialTitle">Talent Partner</div>
                            <a className="TestimonialCompany" href="https://www.linkedin.com/in/crawford-auld-04938a204/?utm_source=taylr&utm_medium=testimonial&utm_campaign=taylr" target="_blank"> mustard</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="TaylrHomePageBlue">
            <div className="TaylrHomePageBenefitsSection">
                <div className="TaylrHomePageYellowTitle">Discover the recruitment benefits of Taylr</div>
                <div className="TaylrHomePageSectionSubtitle">
                    These features will help you write text faster and spend more time talking to clients and candidates
                </div>
                <div className="TaylrHomePageFeatures">
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureIcon">✏️</div>
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">Job Adverts</div>
                            <div className="TaylrHomePageFeatureDescription">Craft well-written job adverts in minutes that appeal to a wide range of candidates.</div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureIcon">🔍</div>
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">Boolean Search Queries</div>
                            <div className="TaylrHomePageFeatureDescription">Create short or complex boolean search queries in seconds and find new candidates.</div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureIcon">🕵</div>
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">The Anonymiser</div>
                            <div className="TaylrHomePageFeatureDescription">Received a written job advert from your client? Quickly remove any identifying information by using the anonymiser tool. </div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureIcon">📧</div>
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">Outreach Emails</div>
                            <div className="TaylrHomePageFeatureDescription">Want to email your client about a new candidate, or share a new role with a candidate? Our emails are short and sweet. </div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureIcon"><BsLinkedin className="LinkedInLogo" /><div className="LinkedInLogoBackground"/></div>
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">LinkedIn Post Ideas</div>
                            <div className="TaylrHomePageFeatureDescription">Overcome writer's block. This feature will give you multiple options on what your next LinkedIn post could be about. </div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureIcon"><BsLinkedin className="LinkedInLogo" /><div className="LinkedInLogoBackground"/></div>
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">Write engaging LinkedIn Posts</div>
                            <div className="TaylrHomePageFeatureDescription">If you're short of time but you want to share more than just job adverts with your network, then this feature is for you. </div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureIcon"><BsLinkedin className="LinkedInLogo" /><div className="LinkedInLogoBackground"/></div>
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">LinkedIn Job Ad</div>
                            <div className="TaylrHomePageFeatureDescription">Simply turn your Job Advert into a ready LinkedIn post at the click of a button.  </div>
                        </div>
                    </div>
                    <div className="TaylrHomePageFeature">
                        <div className="TaylrHomePageFeatureIcon"><BsLinkedin className="LinkedInLogo" /><div className="LinkedInLogoBackground"/></div>
                        <div className="TaylrHomePageFeatureText">
                            <div className="TaylrHomePageFeatureTitle">Create Linkedin Polls</div>
                            <div className="TaylrHomePageFeatureDescription">This is an great way to engage with your network and find out more about your audience. </div>
                        </div>
                    </div>
                </div>
                <a href="https://app.taylr.ai/register" className="TaylrHomeButtonContainer">
                    <Button variant="contained" className="TaylrButton TryNowButton">Get Started</Button>
                </a>
            </div>
        </div>
        <div className="TaylrHomePageSecond">
            <div className="TaylrHomePageSection">
                <div className="TaylrHomePageSecondaryTitle">By Recruiters, for Recruiters</div>
                <div className="TaylrHomePageSecondaryText" style={{textAlign: "left"}}>
                    <p>As recruitment professionals ourselves, we know that efficient time management is absolutely vital.</p>
                    <p>In the past, spending hours on crafting and writing job adverts and emails may have been tolerable, but with the current pace of the industry, it's simply not an option anymore. </p>
                    <p>For example, if you create 10 new job adverts every week, taking approximately 30 min to write each ad, you could be spending 5 hours every week on this task alone. </p>
                    <p>That's a substantial amount of time that could be spent on other vital tasks, such as interviewing candidates or networking with potential clients.</p>
                    <p>With Taylr you can cut this down to 3 minutes per job advert. Why do it any other way?</p>
                </div>
                <a href="https://app.taylr.ai/register" className="TaylrHomeButtonContainer">
                    <Button variant="contained" className="TaylrButton TryNowButton">Get Started</Button>
                </a>
            </div>
        </div>
    </div>

  );
}

export default Home;