import React from 'react';
import { Button, TextField } from '@mui/material';
import "./BooleanSearch.css"
import StreamData from '../functions/StreamData';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';

// const backend_url = "http://localhost:5000/"
const target_url = "boolean-search-open"

const backend_url = "https://api.taylr.ai/"

const Examples = ["I'm looking for an Architect in Manchester using Revit", "Senior Data Scientist with Python in London", "Interior Designer, mid to senior, Edinburgh or Glasgow"]

const RefineExamples = ["Add some nearby locations", "Add similar job titles", "Make it more senior", "Remove...", "Add..."]

function BooleanSearch() {
  const [query, setQuery] = React.useState('');
  const [response, setResponse] = React.useState('');
  const [messages, setMessages] = React.useState([]);
  const [instructions, setInstructions] = React.useState('');
  const [streaming, setStreaming] = React.useState(false);
  const [initialised, setInitialised] = React.useState(false);
  const [refineExamples, setRefineExamples] = React.useState(RefineExamples);
  const [responded, setResponded] = React.useState(false);
  const [loadingExamples, setLoadingExamples] = React.useState(false);
  const responseRef = React.useRef("");

  const handleChange = (event) => {
    //if enter is pressed then submit
    console.log(event)
    if (event.target.id == "input") {
      if (event.keyCode === 13) {
        handleSubmit(event);
      } else {
        setQuery(event.target.value);
      }
      
    }
  };

  const handleSubmit = async (event) => {
    console.log(event)
    try {

      const input = {prompt: {}}
      if (!initialised){
        setInitialised(true)
      } else {
          input.messages = []
          if (messages){
            if (messages.length > 5){
              //only send the first and last 5 messages
              //first message
              input.messages.push(messages[0])
              //last 5 messages
              input.messages.push(...messages.slice(messages.length-5, messages.length))
            } else {
              input.messages = [...messages]
            }
            
          }
          if (responseRef.current) {
            input.messages.push({role: "assistant", name: "Taylr", content: responseRef.current})
          }
          if (messages) {
            input.messages.push({role: "user", name: "You", content: query})
          }
      }

      input.prompt.query = query

      if (response){
        setMessages(prevMessages => [...prevMessages, {role: "assistant", name: "Taylr", content: responseRef.current}, {role: "user", name: "You", content: query}])
        setQuery("")
        setResponse("")
        responseRef.current = ""
      } else {
        setMessages(prevMessages => [...prevMessages, {role: "user", name: "You", content: query}])
        setQuery("")
      }
      
      console.log("Calling StreamData")
      const url = backend_url+target_url
      console.log("Getting Data: ", input)
      await StreamData(setResponse, setStreaming, input, url, responseRef)//"http://localhost:5000/generate_job_description")
      console.log("StreamData returned")
      setResponded(true)
      setLoadingExamples(true)
      axios.post(backend_url+"boolean-search-refine-examples", {prompt: {query: responseRef.current}}).then(res => {
        if (res.data){
          console.log("Refine Examples: ", res.data)
          if (res.data.choices && res.data.choices[0] && res.data.choices[0].message?.content)
          setRefineExamples(res.data.choices[0].message?.content.split("\n").map(example => example.trim().replace("- ", "")))
          
        }
        setLoadingExamples(false)
      })
    } catch (err) {
      setStreaming(false)
      console.error(err);
    }
  };

  return (
    <div className="TaylrHomePage">

        <div className="TaylrHomePageSecond FullHeight BooleanPage">
            <div className="TaylrHomePageSection">
                <div className="TaylrHomePageSecondaryTitle">Free AI Boolean Search Tool</div>
                <div className="TaylrHomePageSecondaryText">
                  Use ChatGPT to generate Boolean Searches
                </div>
              </div>
                <div className="ChatContainer">
                  <div className="MessageArea">
                    {!initialised && <div className="BooleanSearchExamples">
                      {Examples.map(example => <div className="BooleanSearchExample" onClick={() => {setQuery(example)}}>{example}</div>)}
                    </div>}
                    {messages.map(message => <div className={"Message "+message.name+"Message"}>
                      <div className="Name">{message.name}</div>
                      <div className="MessageContent">{message.content}</div>
                      {message.name == "Taylr" && <div className="TaylrMessageButtons">
                        <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">{message.content.split(" ").length>28?"Too many terms for X-Ray search, try reducing the size of your query": "Search Google for public LinkedIn profiles"}</div>} placement="bottom">
                          <span>
                            <Button variant="contained" className="LinkedInSearchButton" disabled={message.content.split(" ").length>28} onClick={() => {window.open(`http://www.google.com/search?q=${message.content}+site:linkedin.com/in/+OR+site:linkedin.com/pub/`, "_blank")}}>LinkedIn Xray</Button>
                          </span>
                        </Tooltip>
                        <Button variant="contained" className="CopyButton" onClick={() => {navigator.clipboard.writeText(response)}}>Copy</Button>
                      </div>}
                    </div>)}
                    {response.length > 0 && <div className={"Message TaylrMessage"+(streaming?"":" TaylrMessageActive")}>
                      <div className="Name">Taylr</div>
                      <div className="MessageContent">{response}</div>
                      <div className="TaylrMessageButtons">
                        <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">{response.split(" ").length>28?"Too many terms for X-Ray search, try reducing the size of your query": "Search Google for public LinkedIn profiles"}</div>} placement="bottom">
                          <span>
                            <Button variant="contained" className="LinkedInSearchButton" disabled={response.split(" ").length>28} onClick={() => {window.open(`http://www.google.com/search?q=${response}+site:linkedin.com/in/+OR+site:linkedin.com/pub/`, "_blank")}}>LinkedIn Xray</Button>
                          </span>
                        </Tooltip>
                        <Button variant="contained" className="CopyButton" onClick={() => {navigator.clipboard.writeText(response)}}>Copy</Button>
                      </div>
                    </div>}
                  </div>
                  <div className="InputContainer">
                    <div className="InputTitle">{!initialised?"What are you looking for?":(loadingExamples?"Loading Refinements...":"Refine this search")}</div>
                    {initialised && [<div className="BooleanSearchRefineExamples">
                        {refineExamples.map(example => <div className="BooleanSearchRefineExample" onClick={() => {setQuery(example.replace("...", " "))}}>{example}</div>)}
                        
                      </div>,
                    //   <div className="BooleanSearchRefineExamples">
                    //     {RefineExamples.map(example => <div className="BooleanSearchRefineExample" onClick={() => {setQuery(example.replace("...", " "))}}>{example}</div>)}
                    // </div>
                  ]}
                    <TextField                       
                        multiline
                        id={"input"}
                        onChange={handleChange}
                        rows={1}
                        value={query}
                        placeholder={!initialised?"What are you looking for?":"How would you like to refine this search?"}>
                        
                    </TextField>
                    <div className="inputButtonContainer TaylrMessage">
                    <Button variant="contained" disabled={query.length==0} className="LinkedInSearchButton BooleanSearchButton" onClick={handleSubmit}>{!initialised?"Generate":"Refine"}</Button>
                    </div>
                    
                  </div>
                </div>
            </div>
    </div>
  );
}

export default BooleanSearch;