import { useState } from 'react';
import { Link } from 'react-router-dom';
import {MdMenu} from 'react-icons/md';
import { Button } from '@mui/material';
function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)

  return (
    <nav className="navigation">
      <Link to="/" className="dashboard-logo">
        <img src="/taylrlogo.png" className="TaylrLogo" alt="Taylr Logo"/>
        TAYLR.<span className="dashboard-logo-ai">AI</span>
      </Link>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
        <MdMenu />
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <a href="https://blog.taylr.ai/" className="NavLink" target="_blank" rel="noopener">Blog</a>
        <Link to="/boolean-search-generator" className="NavLink" onClick={() => setIsNavExpanded(false)}>Boolean Search</Link>
        <Link to="/pricing" className="NavLink" onClick={() => setIsNavExpanded(false)}>Pricing</Link>
        <a href="https://app.taylr.ai/login" className="NavLinkButtonContainer"  onClick={() => setIsNavExpanded(false)}>
            <Button variant="contained" className="TaylrButton NavLinkButton">Sign In</Button>
        </a>
      </div>
    </nav>
  );
}
export default Navbar;