import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const trackPageview = (location) => {
  ReactGA.send({ hitType: "pageview", page:  location.pathname, title: "Taylr Home: "+location.pathname });
};

export const PageviewTracker = () => {
    const location = useLocation();

    useEffect(() => {
        trackPageview(location);
    }, [location]);

    return null;
};
