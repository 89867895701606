import React, { useEffect } from 'react';
import { Button, Modal, TextField } from '@mui/material';
import "./BooleanSearch.css"
import StreamData from '../functions/StreamData';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useLocation } from 'react-router-dom';
import { BsFillLightningChargeFill, BsPlus, BsX, BsCheck } from "react-icons/bs";
import CircularProgress from '@mui/material/CircularProgress';
import {FcGoogle} from "react-icons/fc"
import { DiBingSmall }  from "react-icons/di"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';

// const backend_url = "http://localhost:5000/"
const target_url = "boolean-search-2-generate"

const backend_url = "https://api.taylr.ai/"

const Examples = ["I'm looking for an Architect in Manchester using Revit", "Senior Data Scientist with Python in London", "Interior Designer, mid to senior, Edinburgh or Glasgow"]

const RefineExamples = ["Add some nearby locations", "Add similar job titles", "Make it more senior", "Remove...", "Add..."]

const SearchCategories = [
  {name: "Job Title", values: [], color: "#DCF2FF", addMore: false},
  {name: "Location", values: [], color: "#E7FCD7", addMore: false},
  // {name: "Location (country)", values: [], color: "#D0F8B0"},
  // {name: "Location (city)", values: []},
  {name: "Sectors", values: [], color: "#F7F9B6", separator: "OR"},
  {name: "Skills", values: [], color: "#F8DCD0", separator: "AND"},
  {name: "Company Names", values: [], color: "#FFD8D8", separator: "OR"},
  {name: "Gender & Diversity", values: [], disabled: true, color: "#F5C9E4", separator: "OR"},
  {name: "Experience", values: [], disabled: true, color: "#D3D3D5", separator: "AND"},
  {name: "Other", values: [], color: "#E6E6E6", separator: "AND"},
]

const interleave = (arr, thing) => [].concat(...arr.map(n => [n, thing])).slice(0, -1)


function BooleanSearch() {
  const [query, setQuery] = React.useState('');
  const [response, setResponse] = React.useState({query: "", display: [], queryLength: 0});
  const [searchData, setSearchData] = React.useState({});
  const [generating, setGenerating] = React.useState(false);
  const [modalInputValue, setModalInputValue] = React.useState("");
  const [generatingButtons, setGeneratingButtons] = React.useState({});
  const [initialised, setInitialised] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState({});

  const documentId = React.useRef(uuidv4())
  const originalQueryRef = React.useRef("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // processQueryResponse()
    // getSuggestions({type: "Alternative", category: SearchCategories[0], value: "Architect"})
  }, [])

  useEffect(() => {
    console.log("Location change: ", location)
    if (location.pathname == "/boolean-search-generator" && location.search == ""){
      reset()
    }
  }, [location])


  const reset = () => {
    setQuery("")
    setResponse({query: "", display: [], queryLength: 0})
    setSearchData({})
    setGenerating(false)
    setInitialised(false)
    setModalOpen({})
    documentId.current = uuidv4()
  }

  useEffect(() => {
    let query_list = []
    let display = []
    let queryLength = 0
    SearchCategories.forEach((category) => {
      if (category.disabled) return
      if (searchData[category.name] && searchData[category.name].values && searchData[category.name].values.length > 0){
        let category_query_list = []
        let addBrackets = false
        searchData[category.name].values.map(value => {
          let display_value = value.value
          if (display_value.includes(" ")){
            display_value = `"${display_value}"`
          }
          if (value.alternatives.length > 0){
            if (searchData[category.name].operator == "OR"){
              addBrackets = true
              category_query_list.push(`${display_value} OR ${value.alternatives.map(alternative => alternative.includes(" ") ? `"${alternative}"`: alternative).join(" OR ")}`)
            } else {
              category_query_list.push(`(${display_value} OR ${value.alternatives.map(alternative => alternative.includes(" ") ? `"${alternative}"`: alternative).join(" OR ")})`)
            }
          } else {
            category_query_list.push(`${display_value}`) 
          }
        })
        let separator =  " AND "
        if (searchData[category.name].operator){
          separator = ` ${searchData[category.name].operator} `
        }
        if ((searchData[category.name].operator == "OR" && category_query_list.length > 1) || (addBrackets)){
          display.push(<span className="BooleanSearchCategory" style={{backgroundColor: category.color}}>({category_query_list.join(separator)})</span>)
          query_list.push(`(${category_query_list.join(separator)})`)
        } else {
          display.push(<span className="BooleanSearchCategory" style={{backgroundColor: category.color}}>{category_query_list.join(separator)}</span>)
          query_list.push(category_query_list.join(separator))
        }
        queryLength += category_query_list.length
      }
    })
    display = interleave(display, <span className="BooleanSearchOperator"> AND </span>)
    let query = query_list.join(" AND ")
    setResponse({query: query, display: display, queryLength: queryLength*2-1, wordCount: query.split(" ").length})
  }, [searchData])

  const handleChange = (event) => {
    //if enter is pressed then submit
    console.log(event)
    if (event.target.id == "input") {
      setQuery(event.target.value);
    }
    if (event.target.name.startsWith("OperatorDropdown_")){
      const category = event.target.name.split("_")[1]
      setSearchData(currentSearchData => {
        let newSearchData = {...currentSearchData}
        newSearchData[category].operator = event.target.value
        return newSearchData
      })
    }
  };

  const removeAlternative = (category, value, alternatives) => {
    //list of alternatives
    setSearchData(currentSearchData => {
      let newSearchData = {...currentSearchData}
      let newValues = newSearchData[category].values.map((valueObj) => {
        if (valueObj.value == value){
          // remove alternatives and add to suggestions
          return {...valueObj, alternatives: valueObj.alternatives.filter(alternative => !alternatives.includes(alternative)), suggestions: [...valueObj.suggestions, ...alternatives.filter(alternative => !valueObj.suggestions.includes(alternative))]}
        } else {
          return valueObj
        }
      }) 
      newSearchData[category].values = newValues
      return newSearchData
    })
  }

  const addAlternative = (category, value, alternatives) => {
    setSearchData(currentSearchData => {
      let newSearchData = {...currentSearchData}
      let newValues = newSearchData[category].values.map((valueObj) => {
        if (valueObj.value == value){
          return {...valueObj, alternatives: [...valueObj.alternatives, ...alternatives.filter(alternative => !valueObj.alternatives.includes(alternative))]}
        } else {
          return valueObj
        }
      })
      newSearchData[category].values = newValues
      return newSearchData
    })
  }

  const removeValue = (category, values) => {
    //list of values
    setSearchData(currentSearchData => {
      let newSearchData = {...currentSearchData}
      // remove values and add to suggestedOptions
      newSearchData[category].values = newSearchData[category].values.filter(valueObj => !values.includes(valueObj.value))
      if (newSearchData[category].suggestedOptions){
        newSearchData[category].suggestedOptions = [...newSearchData[category].suggestedOptions, ...values.filter(value => !newSearchData[category].suggestedOptions.includes(value))]
      } else {
        newSearchData[category].suggestedOptions = [...values.filter(value => !newSearchData[category].suggestedOptions.includes(value))]
      }
      return newSearchData
    })
  }

  const addValue = (category, values) => {
    //list of values
    setSearchData(currentSearchData => {
      let newSearchData = {...currentSearchData}
      if (newSearchData[category]){
        newSearchData[category].values = [...newSearchData[category].values, ...values.map(value => {if (!newSearchData[category].values.includes(value)) return {value: value, alternatives: [], input: ""}})]
      } else {
        newSearchData[category] = {values: values.map(value => {return {value: value, alternatives: [], input: ""}}), color: SearchCategories.find(categoryObj => categoryObj.name == category).color, operator: SearchCategories.find(categoryObj => categoryObj.name == category).separator}
      }
      return newSearchData
    })
  }
    



  const getSuggestions = (event) => {
    try {
      if (event.button == "Custom"){
        setGenerating(true)
      } else {
        setGeneratingButtons(currentGeneratingButtons => {
          let newGeneratingButtons = {...currentGeneratingButtons}
          if (!newGeneratingButtons[event.category.name]){
            newGeneratingButtons[event.category.name] = {}
          }
          if (event.value) {
            newGeneratingButtons[event.category.name][event.value] = true
          } else {
            newGeneratingButtons[event.category.name].generating = true
          }
          return newGeneratingButtons
        })
      }
      const input = {prompt: {}}
      input.prompt.category = event.category.name
      const pluralCategory =  (event.category.name.toLowerCase().endsWith("s") || event.category.name.toLowerCase().endsWith("y"))?event.category.name:event.category.name+"s"
      if (event.instructions){
        input.prompt.instructions = event.instructions
      }
      if (event.type == "Alternative"){
        input.prompt.value = event.value
        let alternatives = []
        let valueField = searchData[event.category.name].values.find(value => value.value == event.value)
        if (valueField && valueField.alternatives){
          alternatives = valueField.alternatives
        }
        if (alternatives && alternatives.length > 0){
          input.prompt.alternatives = alternatives
        }
        if (event.button == "Custom" && (modalOpen.open == false || modalOpen.category != event.category.name)){
          setModalOpen({category: event.category, color: event.category.color, alternatives: alternatives, value: event.value, type: "Alternative", open: true, title: `Add Alternative ${pluralCategory} to ${event.value}`, pluralCategory, instructions: ""})
          setModalInputValue("")
        }

      } else if (event.type == "Addition"){
        if (searchData[event.category.name]){
          input.prompt.existing_values = searchData[event.category.name]
          if (searchData[event.category.name].suggestedOptions) {
            input.prompt.existing_suggestions = searchData[event.category.name].suggestedOptions
          }
        }
        if (event.button == "Custom" ){
          input.prompt.prompt_num = 5
          if (modalOpen.open == false || modalOpen.category != event.category.name){
            setModalOpen({category: event.category, color: event.category.color, type: "Addition", open: true, title: `Add Additional ${pluralCategory}`, pluralCategory, instructions: ""})
            setModalInputValue("")
          }
        }
      }
      if (searchData["Job Title"]?.values[0]?.value){
        input.prompt.job_title = searchData["Job Title"]?.values[0]?.value
      }
      if (searchData["Location"]?.values[0]?.value){
        input.prompt.location = searchData["Location"]?.values[0]?.value
      }
      if (originalQueryRef.current){
        input.prompt.original_query = originalQueryRef.current
      }
      if (event.prompt_num){
        input.prompt.prompt_num = event.prompt_num  
      }

      input.prompt.type = event.type
      
      input.documentId = documentId.current

      const url = backend_url+"boolean-search-2-suggest"

      axios.post(url, input).then(res => {
        if (res.data){
          console.log("Query response: ", res.data)
          if (res.data.choices && res.data.choices[0] && res.data.choices[0].message?.content) {
            console.log("response: ", res.data.choices[0].message?.content)
            let message_content = res.data.choices[0].message?.content
            if (message_content.endsWith(".")){
              message_content = message_content.slice(0, -1)
            }
            //replace quotes with nothing
            let options = message_content.split(", ").map(option => option.replace(/"/g, "").trim())
            // if contains a numbered list ie. 1. option 1, 2. option 2 then split on regex
            if (options[0].match(/^[0-9]+\./)){
              options = options.map(option => option.split(". ")[1].trim())  
            }
            if (event.type == "Alternative"){
              let suggestions = [...options]
              if (event.alternatives && event.alternatives.length > 0){
                options = [...event.alternatives, ...options]
              }
              setSearchData(prevSearchData => {
                const newSearchData = {...prevSearchData}
                // find index of value in newSearchData[event.category.name].values
                const index = newSearchData[event.category.name].values.findIndex(value => value.value == event.value)
                if (event.button == "Lightning"){
                  newSearchData[event.category.name].values[index].alternatives = options
                }

                newSearchData[event.category.name].values[index].suggestions = [...suggestions] 
                
                // newSearchData[event.category.name].values.find(value => value.value == event.value).alternatives = options
                return newSearchData
              })
            } else if (event.type = "Addition"){
              setSearchData(prevSearchData => {
                const newSearchData = {...prevSearchData}
                if (!newSearchData[event.category.name]) newSearchData[event.category.name] = {values: [], color: event.category.color, input: "", operator: event.category.separator}
                let existing_values = newSearchData[event.category.name].values.map(value => value.value)
                if (event.button == "Lightning"){
                  options.forEach(option => {
                    if (!existing_values.includes(option)){
                      newSearchData[event.category.name].values.push({value: option, alternatives: [], input: ""})
                    }
                  })
                }
                newSearchData[event.category.name].suggestedOptions = [...options]
                
                return newSearchData
              })

            }
          }
        }
        if (event.button == "Custom"){
          setGenerating(false)
        } else {
          setGeneratingButtons(currentGeneratingButtons => {
            let newGeneratingButtons = {...currentGeneratingButtons}
            if (event.value) {
              newGeneratingButtons[event.category.name][event.value] = false
            } else {
              newGeneratingButtons[event.category.name].generating = false
            }
            return newGeneratingButtons
          })
        }
      })
    } catch (err) {
      if (event.button == "Custom"){
        setGenerating(false)
      } else {
        setGeneratingButtons(currentGeneratingButtons => {
          let newGeneratingButtons = {...currentGeneratingButtons}
          if (event.value) {
            newGeneratingButtons[event.category.name][event.value] = false
          } else {
            newGeneratingButtons[event.category.name].generating = false
          }
          return newGeneratingButtons
        })
      }
      console.error(err);
    }
  }

  const keyPress = (e) => {
    if(e.key == "Enter"){
       e.preventDefault();
       console.log('value', e.target.value);
       if (e.target.id == "input"){
        handleSubmit(e);
       }
       if (e.target.id == "ModalInstructions"){
          if (modalOpen.type == "Addition") {
            getSuggestions({type: "Addition", category: modalOpen.category, button: "Custom", instructions: modalOpen.instructions})
          } else if (modalOpen.type == "Alternative") {
            getSuggestions({type: "Alternative", category: modalOpen.category, value: modalOpen.value, alternatives: modalOpen.alternatives, button: "Custom", instructions: modalOpen.instructions})
          }  
       }
       if (e.target.id.startsWith("AddValue")){
        const category = e.target.id.split("_")[1]
        addValue(category, [e.target.value])
        setSearchData(searchData => {const newSearchData = {...searchData};newSearchData[category].input =""; return newSearchData})
       }
       if (e.target.id.startsWith("AddAlternative_")){
        const category = e.target.id.split("_")[1]
        const value = e.target.id.split("_")[2]
        addAlternative(category, value, [e.target.value])
        setModalInputValue("")
       }

       
    }
  }

  const processQueryResponse = (query) => {
    const example_query = `Query: (reverse engineer OR "reverse engineering") AND "league of legends" AND C++ AND "cheat engine"

Job Title:
reverse engineer

Location: 

Skills:
reverse engineering
C++

Sectors:

Company Names:

Gender & Diversity:

Experience:

Other:
cheat engine`

    if (!query){
      query = example_query
    }
    console.log(query)
    const results = {}

    const regex =  /^([^:\n]+):\s*((?:(?!^(?:[^:\n]+:))[\s\S])*)/gm;

    const sections = [];

    let match;

    while ((match = regex.exec(query)) !== null) {
      const section = {
        section: match[1].trim(),
        content: match[2].trim(),
      };
      sections.push(section);
    }
        
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i].section
      const content = sections[i].content.replace(/[()]/g, "");
      let content_values = []
      if (content.includes(", ")){
        content_values = content.split(", ");
      } else if (content.includes(" AND ")) {
        content_values = content.split(" AND ");
      } else if (content.includes("\n")) {
        content_values = content.split("\n");
      } else {
        content_values = [content]
      }
      let operator
      let color
      // look for section in SearchCategories
      const category = SearchCategories.find(category => category.name.toLowerCase() == section.toLowerCase())
      if (category){
        operator = category.separator
        color = category.color
      }
      const parsed_values = []
      content_values.forEach((value) => {
        let alternatives = []
        let main_value = value.trim()
        if (main_value && main_value.toLowerCase() !== "none" && main_value !== "-" && main_value.toLowerCase() !== "n/a"){
          if (main_value.includes(" OR ")){
            alternatives = main_value.split(" OR ").slice(1);
            main_value = main_value.split(" OR ")[0].trim();
          }
          parsed_values.push({value: main_value, alternatives: alternatives, input: ""})
        }

      })
      if (parsed_values.length > 0)
        results[section] = {values: parsed_values, input: "", operator: operator, color: color};
      
    }
    console.log("Setting query: ", results)
    setSearchData(results)
    navigate("/boolean-search-generator?search=true");
  }

  const handleSubmit = async (event) => {
    console.log(event)
    try {
      setGenerating(true)
      const input = {prompt: {}}

      input.prompt.query = query
      
      const url = backend_url+target_url

      input.documentId = documentId.current

      axios.post(url, {prompt: input.prompt}).then(res => {
        if (res.data){
          console.log("Query response: ", res.data)
          originalQueryRef.current = query
          if (res.data.choices && res.data.choices[0] && res.data.choices[0].message?.content) {
            console.log("response: ", res.data.choices[0].message?.content)
            processQueryResponse(res.data.choices[0].message?.content)
          }
        }
        setGenerating(false)
      })
    } catch (err) {
      setGenerating(false)
      console.error(err);
    }
  };

        // axios.post(backend_url+"boolean-search-refine-examples", {prompt: {query: responseRef.current}}).then(res => {
      //   if (res.data){
      //     console.log("Refine Examples: ", res.data)
      //     if (res.data.choices && res.data.choices[0] && res.data.choices[0].message?.content)
      //     setRefineExamples(res.data.choices[0].message?.content.split("\n").map(example => example.trim().replace("- ", "")))
          
      //   }
      //   setLoadingExamples(false)
      // })

      let modalSelectedValue = {}
      if (modalOpen.type == "Alternative"){
        modalSelectedValue = searchData[modalOpen.category.name].values.filter(value => value.value == modalOpen.value)[0]
      }
      console.log(searchData)
  return (
    <div className="TaylrHomePage">
        <Helmet>
            <meta charSet="utf-8" />
            <title>AI Boolean Search Generator - Taylr.AI</title>
            <link rel="canonical" href={"https://www.taylr.ai"+window.location.pathname} />
            <meta name="description" content={`Use AI to quickly generate simple or complex Boolean Searches` }/>
            <meta name="og:title" content={`AI Boolean Search Generator - Taylr.AI`}/>
            <meta name="og:type" content={"website"}/>
            <meta name="og:url"  href={"https://www.taylr.ai"+window.location.pathname} />
            <meta name="og:image"  content={"https://www.taylr.ai/Taylr_logo_blue_and_yellow_and_blue.png"} />
        </Helmet>
        <div className="TaylrHomePageSecond FullHeight BooleanPage">
            <div className="TaylrHomePageSection">
                <h1 className="TaylrHomePageSecondaryTitle">Taylr's Boolean Search Generator</h1>
                <h2 className="TaylrHomePageSecondaryText">
                  {/* Use Taylr to generate Boolean Searches */}
                  Create boolean strings in seconds using AI
                </h2>
              </div>
              {Object.keys(searchData).length == 0 && [<div className="ChatContainer2">
                  <div className="MessageArea">
                    <div className="BooleanSearchExamples">
                      {Examples.map(example => <div className="BooleanSearchExample" onClick={() => {setQuery(example)}}>{example}</div>)}
                    </div>
                  </div>
                </div>,
                <TextField                       
                        multiline
                        id={"input"}
                        onChange={handleChange}
                        rows={1}
                        value={query}
                        onKeyDown={keyPress}
                        placeholder={"What are you looking for?"}>
                        
                    </TextField>,
                  <div className="inputButtonContainer TaylrMessage" >
                    <div className="GeneratingIcon">{generating && <CircularProgress /> }</div>
                    <Button style={{display: "block", marginTop:"10px"}} variant="contained" disabled={query.length==0 || generating} className="LinkedInSearchButton BooleanSearchButton" onClick={handleSubmit}>{!initialised?"Generate Boolean":"Refine"}</Button>
                </div>
              ]}
              {Object.keys(searchData).length > 0 && <div className="BooleanSearchQuery">
                <div className="BooleanSearchQueryOutputContainer">
                  <div className="BooleanSearchQueryOutputTitle">
                    Your Search Query
                  </div>
                  <div className="BooleanSearchQueryDisplay">
                    {response.display}
                  </div>
                </div>
                <div className="BooleanSearchButtons TaylrMessage">
                    <Button variant="contained" className="CopyButton" onClick={() => {navigator.clipboard.writeText(response.query)}}>Copy</Button>
                    <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">{response.wordCount>40?"Too many terms for X-Ray search, try reducing the size of your query": "Search Google for public LinkedIn profiles"}</div>} placement="bottom">
                      <span>
                        <Button variant="contained" className="LinkedInSearchButton" disabled={response.wordCount>40} onClick={() => {window.open(`http://www.google.com/search?q=site:linkedin.com/in/+OR+site:linkedin.com/pub/+${encodeURIComponent(response.query)}`, "_blank")}}><FcGoogle/> LinkedIn Xray</Button>
                      </span>
                    </Tooltip>
                    <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">{false?"Too many terms for X-Ray search, try reducing the size of your query": "Search Bing for public LinkedIn profiles"}</div>} placement="bottom">
                      <span>
                        <Button variant="contained" className="LinkedInSearchButton" disabled = {false} onClick={() => {window.open(`https://www.bing.com/search?q=site:linkedin.com/in/+${encodeURIComponent(response.query)}`, "_blank")}}><DiBingSmall/> LinkedIn Xray</Button>
                      </span>
                    </Tooltip>
                    
                </div>
                <div className="BooleanSearchSections">
                  <div className="BooleanSearchSectionMainTitle">
                    Refine your search and add alternative terms with AI
                  </div>
                  {SearchCategories.map(category => {if (searchData[category.name] && !category.disabled) {
                    const pluralCategory = (category.name.toLowerCase().endsWith("s") && !category.name.toLowerCase().endsWith("y"))?category.name:category.name+"s"
                   return <div className="BooleanSearchSection" style={{backgroundColor: category.color}}>
                      <div className="BooleanSearchSectionGrid">
                        <div className="BooleanSearchSectionTitle">{category.name}</div>
                        <div className="BooleanSearchSectionAlternativeTitle">
                          Alternatives 
                          {searchData[category.name].operator && <div className="BooleanOperatorDropdownContainer">
                              <Select
                                size="small"
                                id={`OperatorDropdown_${category.name}`}
                                name={`OperatorDropdown_${category.name}`}
                                value={searchData[category.name].operator}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                className="BooleanOperatorDropdown"
                                onChange={handleChange}
                              >
                                <MenuItem value={"AND"} style={{fontSize: "12px"}}>AND</MenuItem>
                                <MenuItem value={"OR"} style={{fontSize: "12px"}}>OR</MenuItem>
                              </Select>
                          </div>}
                        </div>
                        {searchData[category.name] && searchData[category.name].values && searchData[category.name].values.map(value => {
                          return [<div className="BooleanSearchSectionValue"><div className="BooleanSearchSectionAlternative">{value.value}<div className="RemoveValue" onClick={() => {removeValue(category.name, [value.value])}}><BsX/></div></div></div>,
                          <div className="BooleanSearchSectionAlternatives">
                            {value.alternatives.map(alternative => <div className="BooleanSearchSectionAlternative">{alternative}<div className="RemoveValue" onClick={() => {removeAlternative(category.name, value.value, [alternative])}}><BsX/></div></div>)}
                            <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents"> Generate 5 alternative {pluralCategory} to {value.value}</div>} placement="top">
                              <div className="BooleanAddButton" onClick={() => {if (!(generatingButtons[category.name] && generatingButtons[category.name][value.value])) {getSuggestions({type: "Alternative", category: category, value: value.value, alternatives: value.alternatives, button: "Lightning"})}}}>{generatingButtons[category.name] && generatingButtons[category.name][value.value]?<CircularProgress className="GeneratingIconButton" />:<BsFillLightningChargeFill className='LightningIcon'/>}</div>
                            </Tooltip>
                            <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents"> Customise alternative {pluralCategory} to {value.value}</div>} placement="top">
                            <div className="BooleanAddButton" onClick={() => {getSuggestions({type: "Alternative", category: category, value: value.value, alternatives: value.alternatives, button: "Custom"})}}><BsPlus/></div>
                          </Tooltip>
                          </div>]
                        })}
                      </div>
                      {(category.addMore !== false || searchData[category.name] && searchData[category.name].values && searchData[category.name].values.length == 0) && <div className="BooleanAddtoSection">
                        <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents"> Generate 2 Additional {pluralCategory}</div>} placement="top">
                          <div className="BooleanAddButton" onClick={() => {if (!(generatingButtons[category.name] && generatingButtons[category.name].generating)) {getSuggestions({type: "Addition", category: category, button: "Lightning", prompt_num: category.addMore? 1:2})}}}>{generatingButtons[category.name] && generatingButtons[category.name].generating?<CircularProgress className="GeneratingIconButton" />:<BsFillLightningChargeFill className='LightningIcon'/>}</div>
                        </Tooltip>
                        <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents"> Add Custom {pluralCategory}</div>} placement="top">
                          <div className="BooleanAddButton" onClick={() => {getSuggestions({type: "Addition", category: category, button: "Custom"})}}><BsPlus/></div>
                        </Tooltip>
                        <TextField
                          id={`AddValue_${category.name}`}
                          placeholder='Add Custom'
                          rows={1}
                          value={searchData[category.name].input}
                          onChange={e => {setSearchData(searchData => {
                            const newSearchData = {...searchData}
                            newSearchData[category.name].input = e.target.value; 
                            return newSearchData
                          })}}
                          onKeyDown={keyPress}
                          variant="outlined"
                          className="AddValueTextField"
                          autoComplete="off"
                        />
                        {searchData[category.name].input && <div className="BooleanTickButton" onClick={() => {addValue(category.name, [searchData[category.name].input]); setSearchData(searchData => {const newSearchData = {...searchData};newSearchData[category.name].input =""; return newSearchData})}}><BsCheck/></div>}
                        {searchData[category.name].input && <div className="BooleanCrossButton" onClick={() => {setSearchData(searchData => {const newSearchData = {...searchData};newSearchData[category.name].input =""; return newSearchData})}}><BsX/></div>}
                      </div>}
                    </div>}
                  })}
                </div>
                <div className="BooleanAdditionalSections">
                  {SearchCategories.map(category => {if (!searchData[category.name] && !category.disabled) { 
                    return <div className="BooleanSearchAddSection" style={{backgroundColor: category.color}} onClick={() => {getSuggestions({type: "Addition", category: category, button: "Custom"})}}>
                      Add {category.name}
                      </div>}
                  })}

                </div>
                <Modal open = {modalOpen && modalOpen.open} onClose={() => {setModalOpen(modalOpen => modalOpen.open = false)}} className="ModalContainer">
                  <div className="Modal" style={{backgroundColor: modalOpen.color}}>
                    <div className="ModalTitle">{modalOpen.title}</div>
                    {modalOpen.type == "Addition" && <div className="ModalContent">
                      <div className="ModalSection">
                        <div className="ModalSectionTitle">
                          Current {modalOpen.pluralCategory}: 
                        </div>
                        <div className="ModalSectionValues">
                          {searchData[modalOpen.category.name] && searchData[modalOpen.category.name].values && searchData[modalOpen.category.name].values.map(value => <div className="BooleanSearchSectionAlternative">{value.value}<div className="RemoveValue" onClick={() => {removeValue(modalOpen.category.name, [value.value])}}><BsX/></div></div>)}
                        </div>
                      </div>
                      <div className="ModalAddSection">
                        <TextField
                            id={`AddValue_${modalOpen.category.name}_Modal`}
                            placeholder='Add Custom'
                            rows={1}
                            value={searchData[modalOpen.category.name] && searchData[modalOpen.category.name].input}
                            onChange={e => {setSearchData(searchData => {
                              const newSearchData = {...searchData}
                              newSearchData[modalOpen.category.name].input = e.target.value; 
                              return newSearchData
                            })}}
                            onKeyDown={keyPress}
                            variant="outlined"
                            className="AddValueTextField"
                            autoComplete="off"
                          />
                          {searchData[modalOpen.category.name] && searchData[modalOpen.category.name].input && <div className="BooleanTickButton" onClick={() => {addValue(modalOpen.category.name, [searchData[modalOpen.category.name].input]); setSearchData(searchData => {const newSearchData = {...searchData};newSearchData[modalOpen.category.name].input =""; return newSearchData})}}><BsCheck/></div>}
                          {searchData[modalOpen.category.name] && searchData[modalOpen.category.name].input && <div className="BooleanCrossButton" onClick={() => {setSearchData(searchData => {const newSearchData = {...searchData};newSearchData[modalOpen.category.name].input =""; return newSearchData})}}><BsX/></div>}
                      </div>
                      <div className="ModalSection">
                        <div className="ModalSectionTitle">
                          Suggested {modalOpen.pluralCategory}: <span className="ModalSectionInstructions">Click to Add</span>
                        </div>
                        <div className="ModalSectionValues">
                          {generating && <CircularProgress className="GeneratingIconMenu"/> }
                          {searchData[modalOpen.category.name] && searchData[modalOpen.category.name].suggestedOptions && searchData[modalOpen.category.name].suggestedOptions.map(value => <div className={searchData[modalOpen.category.name].values.map(existingValue => existingValue.value).includes(value)?"BooleanSearchSectionAlternative Pointer Included":"BooleanSearchSectionAlternative Pointer"} onClick={() => {addValue(modalOpen.category.name, [value])}}>{value}</div>)}
                        </div>
                      </div>
                    </div>}
                    {modalOpen.type == "Alternative" && <div className="ModalContent">
                      <div className="ModalSection">
                        <div className="ModalSectionTitle">
                          Current Alternatives: 
                        </div>
                        <div className="ModalSectionValues">
                          {modalSelectedValue.alternatives && modalSelectedValue.alternatives.map(value => <div className="BooleanSearchSectionAlternative">{value}<div className="RemoveValue"  onClick={() => {removeAlternative(modalOpen.category.name, modalOpen.value, [value])}}><BsX/></div></div>)}
                          <div className="BooleanSearchSectionAlternative Pointer" onClick = {() => {removeAlternative(modalOpen.category.name, modalOpen.value, modalSelectedValue.alternatives)}}> 
                            <BsX/> Remove All
                          </div> 
                        </div>
                      </div>
                      <div className="ModalAddSection">
                        <TextField
                            id={`AddAlternative_${modalOpen.category.name}_${modalOpen.value}_Modal`}
                            placeholder='Add Custom'
                            rows={1}
                            value={modalInputValue}
                            onChange={e => {setModalInputValue(e.value)}}
                            onKeyDown={keyPress}
                            variant="outlined"
                            className="AddValueTextField"
                            autoComplete="off"
                          />
                          {searchData[modalOpen.category.name].input && <div className="BooleanTickButton" onClick={() => {addAlternative(modalOpen.category.name, modalOpen.value, [modalInputValue]); setModalInputValue("")}}><BsCheck/></div>}
                          {searchData[modalOpen.category.name].input && <div className="BooleanCrossButton" onClick={() => {setModalInputValue("")}}><BsX/></div>}
                      </div>
                      <div className="ModalSection">
                        <div className="ModalSectionTitle">
                          Suggested Alternatives: <span className="ModalSectionInstructions">Click to Add</span>
                        </div>
                        <div className="ModalSectionValues">
                          {generating && <CircularProgress className="GeneratingIconMenu"/> }
                          {modalSelectedValue.suggestions && modalSelectedValue.suggestions.map(value => <div className={modalSelectedValue.alternatives.includes(value)?"BooleanSearchSectionAlternative Pointer Included":"BooleanSearchSectionAlternative Pointer"}  onClick={() => {addAlternative(modalOpen.category.name, modalOpen.value, [value])}}>{value}</div>)}
                          <div className="BooleanSearchSectionAlternative Pointer" onClick = {() => {addAlternative(modalOpen.category.name, modalOpen.value, modalSelectedValue.suggestions)}}> 
                            <BsPlus/> Add All
                          </div> 
                        </div>
                      </div>
                    </div>}
                    <div className="ModalInputs">
                      <TextField
                        id="ModalInstructions"
                        placeholder='Add some instructions'
                        rows={1}
                        value={modalOpen.instructions}
                        onChange={e => {setModalOpen(modalOpen => {
                          const newModalOpen = {...modalOpen}
                          newModalOpen.instructions = e.target.value
                          return newModalOpen
                        })}}
                        onKeyDown={keyPress}
                        variant="outlined"
                        className="ModalInstructions"
                        autoComplete="off"
                      />
                      <div className="BooleanSearchButtons TaylrMessage">
                        {modalOpen.type == "Addition" && <Button variant="contained" className="CopyButton" onClick={() => {getSuggestions({type: "Addition", category: modalOpen.category, button: "Custom", instructions: modalOpen.instructions})}}>Generate Suggestions</Button>}
                        {modalOpen.type == "Alternative" && <Button variant="contained" className="CopyButton" onClick={() => {getSuggestions({type: "Alternative", category: modalOpen.category, value: modalOpen.value, alternatives: modalOpen.alternatives, button: "Custom", instructions: modalOpen.instructions})}}>Generate Suggestions</Button>}
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>}
            </div>
    </div>
  );
}

export default BooleanSearch;