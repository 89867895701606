import { useLocation, BrowserRouter as Router, Routes , Route, Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Checkout from './pages/Checkout';
import BooleanSearch from './pages/BooleanSearch';
import BooleanSearch2 from './pages/BooleanSearch2';
import './App.css'
import { PageviewTracker } from './GoogleAnalytics';
import Footer from './components/Footer';

function App() {


  return (
    <Router>
      <PageviewTracker />
      <Navbar />
        <Routes >
          <Route exact path="/" element = { <Home />} />
          <Route path="/pricing" element = { <Pricing />} />
          <Route path="/boolean-search-generator" element = { <BooleanSearch2 />} />
          <Route path="/boolean-search-chat" element = { <BooleanSearch />} />
          {/* <Route path="/checkout" element = { <Checkout />} /> */}
        </Routes >
        {/* <Footer/> */}
    </Router>
  );
}

export default App;
