import request from "request";

export default async function StreamData(setResponse, setStreaming, input, url, responseRef) {
    return new Promise((resolve, reject) => {
        try {
            const textDecoder = new TextDecoder()
            setResponse("")
            responseRef.current = ""
            setStreaming(true)
            // console.log(outletProps)

            const fetchOptions = {
            method: 'POST',
            url: url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),
            };
            let tempText = ""
            console.log("fetchOptions: ", fetchOptions)
            request(fetchOptions).on('response', (response) => {
            if (response.statusCode==403) {
                const response_json = response.toJSON()
                console.log(response_json)
                setStreaming(false)
            }
            })
            .on('data', (chunk) => {
                // process the chunk of data here
                let decodedChunk = textDecoder.decode(chunk)
                // console.log(decodedChunk)
                if (decodedChunk == "Email not Verified") {
                    console.log("email not verified")
                    alert("Email not Verified, please verify your email and try again")
                    return
                }
                if (decodedChunk.startsWith(`{"Error":"Insufficient Credits"`)) {
                    console.log("Insufficient Credits")
                    alert("Out of Credits, please upgrade your plan")
                    return
                }
                decodedChunk = decodedChunk.slice(6)
                if (decodedChunk.endsWith("data: [DONE]")) {
                    decodedChunk = decodedChunk.slice(0, -12)
                }
                let chunks = []
                if (decodedChunk.includes("data: ")){
                    chunks = decodedChunk.split("data: ")
                } else {
                    chunks = [decodedChunk]
                }
                chunks.forEach(decodedChunk => {
                decodedChunk = decodedChunk.trim()
                // console.log(decodedChunk)
                if (decodedChunk.startsWith("{") && !decodedChunk.endsWith("}")){
                    tempText = decodedChunk
                    return
                }
                if (!decodedChunk.startsWith("{") && decodedChunk.endsWith("}") && tempText != ""){
                    decodedChunk = tempText + decodedChunk
                    tempText = ""
                }
                if(decodedChunk.startsWith("{") && decodedChunk.endsWith("}")){
                    const JsonChunk = JSON.parse(decodedChunk)
                    tempText = ""
                    if (JsonChunk.credits_used){
                        console.log("CREDITS USED: ", JsonChunk.credits_used)
                        console.log("CREDITS REMAINING: ", JsonChunk.total_credits)
                        if (JsonChunk.total_credits < 0){
                            JsonChunk.total_credits = 0
                        }
                        return
                    }
                    setResponse(prevResponse => {
                            if (JsonChunk.choices && JsonChunk.choices[0] && JsonChunk.choices[0].delta && JsonChunk.choices[0].delta.content){
                                let new_text = JsonChunk.choices[0].delta.content
                                console.log(new_text)
                                if (!new_text){
                                    return prevResponse
                                }
                                if (prevResponse == ""){
                                    responseRef.current = new_text.replace("\n", "")
                                    return new_text.replace("\n", "")
                                }
                                if (new_text.includes("\u2022")){
                                    const prevResponseEnding = prevResponse.slice(Math.min(prevResponse.length-50, 0), prevResponse.length)
                                    const prevResponseEndingNoSpace = prevResponseEnding.replaceAll(" ", "")
                                    if (!(prevResponseEndingNoSpace.endsWith("\n") && !new_text.includes("\n"))) {
                                        responseRef.current = prevResponse + new_text.replace("\u2022", "\n\u2022")
                                        return prevResponse + new_text.replace("\u2022", "\n\u2022")
                                    } else if (prevResponseEndingNoSpace.endsWith("\n\n")){
                                        let n = prevResponse.lastIndexOf("\n\n");
                                        if (n >= 0 && n + 2 >= prevResponse.length) {
                                            prevResponse = prevResponse.substring(0, n)+"\n"+prevResponse.substring(n + 2);
                                        }
                                        responseRef.current = prevResponse + new_text.replace("\u2022", "\n\u2022")
                                        return prevResponse + new_text.replace("\u2022", "\n\u2022")
                                    }
                                }
                                responseRef.current = prevResponse+new_text.replace("\u00a0", "")
                                return prevResponse+new_text.replace("\u00a0", "")
                            } else {
                                return prevResponse
                            }
                        })
                    } else {
                        console.log("COULDNT PARSE: ", decodedChunk)
                        if (decodedChunk.startsWith("<head><title>503 Service Temporarily Unavailable</title></head>")){
                            setResponse("503 Service Temporarily Unavailable, check https://status.openai.com/ for updates")
                        }
                    }
                })
            })
            .on('error', (err) => {
                console.log(`Caught exception: ${err}`);
                setStreaming(false)
                console.error(err);
                reject(err);
              })
            .on('end', () => {
                setStreaming(false)
                resolve();
                // all the data has been received, so you can loop over the responses here
            })

            
            ;
            // 
        } catch (err) {
            console.log("Error in streaming data")
            setStreaming(false)
            console.error(err);
            reject(err);
        }
    });
}